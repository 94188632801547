.tox:not([dir="rtl"]) {
  border: none;
}

.tox-toolbar,
.tox-toolbar__primary {
  display: flex;
  justify-content: center;
  width: 100%;
}
